html {
  width: 100%;
  height: 100%;
  background-color: #f5fcff;
}

img {
  border-radius: 60%;
}

.text {
  color: #3e5b67;
  font-family:'system-ui';
}

.container {
  display: grid;
  grid-template-columns: 37% 61%;
  height: 100vh;
}

@media (max-width: 1000px) {
  .right {
    max-width: 85%;
    padding-top: 25px;
  }
}

.h-align {
  display: flex;
  justify-content: center;
  align-items: right;
}

.twitter-icon-padding {
  padding-left: 10px;
}

.clickable-text {
  text-decoration: underline;
  cursor: pointer;
  color: #3600EE;
  font-family:'system-ui';
}

.horizontal-line {
  border: none;
  border-top: 1px solid #000;
  width: 250px;
}
